import React from "react";
import { Link } from "gatsby";
import g from "glamorous";
import { css } from "glamor";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton
} from "react-accessible-accordion";

import "react-accessible-accordion/dist/fancy-example.css";

export default () => (
  <Wrapper>
    <Title>All procedures</Title>
    <Accordion allowZeroExpanded={true}>
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <Zone>
              Face <Arrow />
            </Zone>
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <Link {...cssLink} to="/en/skin/biorevitalization-peel-prx-t33/">
            Biorevitalization peel PRX-T33
          </Link>
          <Link {...cssLink} to="/en/face/crows-feet-chemical-peel/">
            Chemical peel for crow's feet
          </Link>
          <Link {...cssLink} to="/en/visage/face-treatment-diagnosis/">
            Diagnosis for face treatments
          </Link>
          <Link {...cssLink} to="/en/face/hyaluronic-acid-wrinkles/">
            Hyaluronic acid fillers for wrinkles
          </Link>
          <Link {...cssLink} to="/en/face/hydrafacial/">
            HydraFacial
          </Link>
          <Link {...cssLink} to="/en/face/jaw-reduction-botox/">
            Jaw reduction using Botox
          </Link>
          <Link {...cssLink} to="/en/skin/keybody-skin/">
            Keybody-Skin
          </Link>
          <Link {...cssLink} to="/en/skin/laser-icon-skin-imperfections/">
            Laser Icon
          </Link>
          <Link {...cssLink} to="/en/face/mesolift-nctf/">
            Mesolift NCTF 135
          </Link>
          <Link {...cssLink} to="/en/face/skinbooster/">
            Skinbooster
          </Link>
          <Link {...cssLink} to="/en/face/bruxism-teeth-grinding/">
            Bruxism: Teeth grinding
          </Link>
        </AccordionItemPanel>
      </AccordionItem>
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <Zone>
              Eyes <Arrow />
            </Zone>
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <Link {...cssLink} to="/en/eyes/blepharoplasty-eyelid-surgery/">
            Blepharoplasty: Eyelid surgery
          </Link>
          <Link {...cssLink} to="/en/face/crows-feet-chemical-peel/">
            Chemical peel for crow's feet
          </Link>
          <Link {...cssLink} to="/en/eyes/double-eyelid-asian-blepharoplasty/">
            Double eyelid surgery: Asian blepharoplasty
          </Link>
        </AccordionItemPanel>
      </AccordionItem>
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <Zone>
              Nose <Arrow />
            </Zone>
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <Link {...cssLink} to="/en/nose/rhinoplasty/">
            Rhinoplasty: nose surgery
          </Link>
          <Link {...cssLink} to="/en/nose/septoplasty/">
            Septoplasty: deviated nasal septum correction
          </Link>
        </AccordionItemPanel>
      </AccordionItem>
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <Zone>
              Ears <Arrow />
            </Zone>
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <Link {...cssLink} to="/en/ears/otoplasty-prominent-ears-surgery/">
            Otoplasty : Prominent ear surgery
          </Link>
          <Link
            {...cssLink}
            to="/en/ears/split-torn-stretched-earlobe-surgery/"
          >
            Split, torn or stretched earlobe surgery
          </Link>
        </AccordionItemPanel>
      </AccordionItem>
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <Zone>
              Breast <Arrow />
            </Zone>
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <Link {...cssLink} to="/en/breast/breast-lift/">
            Breast Lift : Breast ptosis correction
          </Link>
          <Link {...cssLink} to="/en/breast/breast-implants/">
            Breast implants
          </Link>
          <Link {...cssLink} to="/en/breast/breast-lipofilling/">
            Breast lipofilling
          </Link>
          <Link {...cssLink} to="/en/breast/breast-reconstruction-surgery/">
            Breast reconstruction surgery
          </Link>
          <Link {...cssLink} to="/en/breast/breast-reduction/">
            Breast reduction
          </Link>
        </AccordionItemPanel>
      </AccordionItem>
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <Zone>
              Legs <Arrow />
            </Zone>
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <Link
            {...cssLink}
            to="/en/body-contouring/cryolipolysis-coolsculpting/"
          >
            Cryolipolyse with Coolsculpting
          </Link>
          <Link {...cssLink} to="/en/body-contouring/keybody-lipo/">
            Keybody-Lipo
          </Link>
          <Link {...cssLink} to="/en/body-contouring/xbody-reshape-body-ems/">
            XBody Training
          </Link>
        </AccordionItemPanel>
      </AccordionItem>
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <Zone>
              Body contouring <Arrow />
            </Zone>
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <Link {...cssLink} to="/body-contouring/consultation/">
            Body Contouring Consultation
          </Link>
          <Link {...cssLink} to="/en/body-contouring/body-lift/">
            Body lift
          </Link>
          <Link {...cssLink} to="/en/body-contouring/buttock-plastic-surgery/">
            Buttock plastic surgery
          </Link>
          <Link
            {...cssLink}
            to="/en/body-contouring/cryolipolysis-coolsculpting/"
          >
            Cryolipolyse with Coolsculpting
          </Link>
          <Link {...cssLink} to="/en/body-contouring/emsculpt/">
            EMSculpt
          </Link>
          <Link {...cssLink} to="/en/body-contouring/keybody-lipo/">
            Keybody-Lipo
          </Link>
          <Link {...cssLink} to="/en/body-contouring/liposuction/">
            Liposuction
          </Link>
          <Link {...cssLink} to="/en/body-contouring/tummy-tuck/">
            Tummy tuck - Abdominoplasty
          </Link>
          <Link {...cssLink} to="/en/body-contouring/xbody-reshape-body-ems/">
            XBody Training
          </Link>
        </AccordionItemPanel>
      </AccordionItem>
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <Zone>
              Genitals <Arrow />
            </Zone>
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <Link {...cssLink} to="/en/genital/g-spot-amplification/">
            G-spot amplification
          </Link>
          <Link {...cssLink} to="/en/genitals/labiaplasty-labia-reduction/">
            Labiaplasty
          </Link>
        </AccordionItemPanel>
      </AccordionItem>
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <Zone>
              Legs <Arrow />
            </Zone>
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <Link
            {...cssLink}
            to="/en/body-contouring/cryolipolysis-coolsculpting/"
          >
            Coolsculpting
          </Link>
          <Link {...cssLink} to="/en/body-contouring/emsculpt/">
            Sculpt your buttocks with EMSculpt
          </Link>
          <Link {...cssLink} to="/en/body-contouring/keybody-lipo/">
            Keybody-Lipo
          </Link>
          <Link {...cssLink} to="/en/body-contouring/xbody-reshape-body-ems/">
            XBody training
          </Link>
        </AccordionItemPanel>
      </AccordionItem>
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <Zone>
              Skin <Arrow />
            </Zone>
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <Link {...cssLink} to="/en/skin/biorevitalization-peel-prx-t33/">
            Biorevitalization peel PRX-T33
          </Link>
          <Link {...cssLink} to="/en/body-contouring/body-lift/">
            Body lift
          </Link>
          <Link {...cssLink} to="/en/visage/face-treatment-diagnosis/">
            Diagnosis for face treatments
          </Link>
          <Link {...cssLink} to="/en/face/hydrafacial/">
            HydraFacial
          </Link>
          <Link {...cssLink} to="/en/body-contouring/keybody-lipo/">
            Keybody-Lipo
          </Link>
          <Link {...cssLink} to="/en/skin/keybody-skin/">
            Keybody-Skin
          </Link>
          <Link {...cssLink} to="/en/skin/laser-icon-skin-imperfections/">
            Laser Icon
          </Link>
          <Link {...cssLink} to="/en/face/mesolift-nctf/">
            Mesolift NCTF 135
          </Link>
          <Link {...cssLink} to="/en/skin/scar-revision/">
            Scar surgery
          </Link>
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  </Wrapper>
);

const Wrapper = g.div({
  borderRadius: `4px`,
  backgroundColor: `white`,
  color: `#1f1f1f`,
  border: `1px solid rgba(73,105,126,0.08)`,
  boxShadow: `0 1px 7px rgba(0,0,0,.05)`,
  margin: `2em 0 2em`,
  padding: `.75em`,
  transition: `all 0.15s ease-in-out`,
  ":hover": {
    border: `1px solid rgba(73,105,126,0.18)`,
    boxShadow: `0 1px 9px rgba(0,0,0,.2)`,
    transition: `all 0.1s ease-in-out`
  }
});

const Title = g.div({
  margin: `0 auto 1em`,
  letterSpacing: `0.05em`,
  fontSize: `1.2em`,
  paddingBottom: `3px`,
  color: `#49697e`,
  borderBottom: `2px solid #49697e`,
  fontWeight: `bold`
});

const Zone = g.div({
  fontSize: `1.05em`,
  color: `#49697e`,
  fontWeight: `500`,
  padding: `.2em`,
  borderBottom: `1px solid rgba(14, 36, 69, 0.2)`,
  marginBottom: `.35em`,
  position: `relative`,
  "&::focus": {
    outline: `none`,
    color: `red`
  }
});

const Arrow = g.div({
  display: `inline-block`,

  width: `24px`,
  height: `12px`,
  position: `absolute`,
  top: `50%`,
  right: `0`,
  marginTop: `-6px`,
  "&::before": {
    display: `block`,
    position: `absolute`,
    top: `50%`,
    width: `10px`,
    height: `2px`,
    backgroundColor: `#49697e`,
    content: ` `,
    left: `4px`,
    transform: `rotate(45deg)`
  },
  "&::after": {
    display: `block`,
    position: `absolute`,
    top: `50%`,
    width: `10px`,
    height: `2px`,
    backgroundColor: `#49697e`,
    content: ` `,
    right: `4px`,
    transform: `rotate(-45deg)`
  }
});

css.global(".accordion__title", {
  letterSpacing: `0em`,
  transition: `all .15s ease-in-out`,
  cursor: `pointer`
});

css.global(".accordion__title:focus", {
  outline: "none",
  letterSpacing: `.045em`,
  transition: `all .15s ease-in-out`
});

const cssLink = css({
  display: `block`,
  fontSize: `.9em`,
  color: `#3f3f3f`,
  paddingLeft: `1px`,
  lineHeight: `1.5em`,
  margin: `.55em 0`,
  "&:hover": {
    backgroundColor: `hsla(193,5%,62%,.2)`
  }
});
