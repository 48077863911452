import React from "react";
import g from "glamorous";
import { css } from "glamor";
import { Link } from "gatsby";
import { FaPlus } from "react-icons/fa";


const Div = g.div({
  borderRadius: `0 0 4px 4px`,
  padding: `12px`,
  backgroundColor: `white`,
  color: `#1f1f1f`,
  border: `1px solid rgba(73,105,126,0.08)`,
  boxShadow: `0 1px 7px rgba(0,0,0,.05)`,
  margin: `2em 0`,
  transition: `all 0.15s ease-in-out`,
  ":hover": {
    border: `1px solid rgba(73,105,126,0.18)`,
    boxShadow: `0 1px 9px rgba(0,0,0,.2)`,
    transition: `all 0.1s ease-in-out`
  }
});

const Title = g.div({
  margin: `0 auto 1em`,
  letterSpacing: `0.05em`,
  fontSize: `1.2em`,
  paddingBottom: `3px`,
  color: `#49697e`,
  borderBottom: `2px solid #49697e`,
  fontWeight: `bold`
});

const cssLink = css({
  display: `block`,
  marginBottom: `1em`,
  fontSize: `15`,
  lineHeight: `21px`,
  color: `#1f1f1f`,
  ":hover": {
    textDecoration: `underline`
  }
});

const cssPlus = css({
  fontSize: `1em`,
  padding: `0 .4em 0.07em`,
  color: `#49697e`
});

export default ({ ...props }) => (
  <Div>
    <Title>Information</Title>

    <Link to="/en/before-plastic-surgery-procedures/" {...cssLink}>
      <FaPlus {...cssPlus} />
      Before a plastic surgery procedures
    </Link>

    <Link to="/en/risks-plastic-surgery/" {...cssLink}>
      <FaPlus {...cssPlus} />
      Risks in plastic and cosmetic surgery
    </Link>

    <Link
      to="/en/reimbursement-plastic-surgery-procedures/"
      {...cssLink}
    >
      <FaPlus {...cssPlus} />
      Reimbursement of plastic and reconstructice surgery procedures
    </Link>
  </Div>
);
